export type CreatePaymentResponse = {
    id: string
    order_state: string
    amount: number
    currency: string
    payment_method_type: string
    payment_intent_client_secret: string
    confirmation_code: string
    created_at: string
    updated_at: string
}

export class OrderService {
    constructor(
        private readonly baseURL: string
    ) { }

    private url(...parts: string[]) {
        const path = parts.join('/')
        return `${this.baseURL}/${path}`
    }

    async createPayment(orderID: string) {
        return fetch(this.url('orders', orderID, 'payments'), {
            method: 'post'
        })
        .then(res => res.json())
        .then(res =>  res as CreatePaymentResponse)
    }

}

export const DefaultOrderService = new OrderService("https://6csuw7bnae.execute-api.us-east-1.amazonaws.com")
