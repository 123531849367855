import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
    getDetails
} from "use-places-autocomplete"
import useOnclickOutside from "react-cool-onclickoutside"

export const PlacesAutocomplete = () => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            // TODO restrict to full addresses
        },
        debounce: 300,
    })

    const ref = useOnclickOutside(() => {
        clearSuggestions()
    })

    const handleInput = (e: any) => {
        setValue(e.target.value)
    }

    const handleSelect = (response: google.maps.places.AutocompletePrediction) => async () => {
        const { description } = response
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false)
        clearSuggestions()
        const placeDetails = await getDetails({ placeId: response.place_id })
            .then(res => res as google.maps.places.PlaceResult)
            .then(console.log)
        // placeDetails.
     
    };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={place_id} onClick={handleSelect(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    return (
        <div ref={ref}>
            <input
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder="Where are you going?"
            />
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            {status === "OK" && <ul>{renderSuggestions()}</ul>}
        </div>
    );
};