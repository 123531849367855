import React from 'react'
import logo from './logo.svg'
import './App.css'

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate
} from "react-router-dom"

import { GetBoard } from './pages/GetBoard'
import { CreateBoard } from './pages/CreateBoard'

const NoPage = () => {
  return <div>
    No Page
    <ul>
      <li><Link to="/">Home</Link></li>
      <li><Link to="/boards/new">Create Board</Link></li>
      <li><Link to="/boards/:board_id/edit">Edit Board</Link></li>
      {/* Redirect to full */}
      <li><Link to="/b/Hf8kJ7KjLr2jToGogAgdLX">Get Board Short</Link></li>
      <li><Link to="/boards/Hf8kJ7KjLr2jToGogAgdLX">Get Board</Link></li>
      <li><Link to="/orders/:order_id/payments/new">Pay</Link></li>
      <li><Link to="/orders/:order_id/payments/pending">Payment Pending</Link></li>
      <li><Link to="/orders/:order_id/complete">Order Complete</Link></li>
    </ul>
  </div>
}

const RenderRoutes = () => {
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    return <Navigate to={path} />
  }

  return <Routes>
    <Route path="/" element={<NoPage />} />
    <Route path="/boards/new" element={<CreateBoard />}>Create Board</Route>
    <Route path="/boards/:board_id/edit" element={<NoPage />}>Edit Board</Route>
    <Route path="/b/:board_id" element={<GetBoard />}>Get Board Short</Route>
    <Route path="/boards/:board_id" element={<GetBoard />}>Get Board</Route>
    <Route path="/orders/:order_id/payments/new" element={<NoPage />}>Pay</Route>
    <Route path="/orders/:order_id/payments/pending" element={<NoPage />}>Payment Pending</Route>
    <Route path="/orders/:order_id/complete" element={<NoPage />}>Order Complete</Route>
  </Routes>
}

export const App = () => {

  return (
    <BrowserRouter>
        <RenderRoutes />
    </BrowserRouter>
  );
}

