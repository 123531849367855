import React from 'react'
import { useParams } from 'react-router'

import { DefaultBoardService, GetBoardResponse } from '../services/BoardService'

export const GetBoard = () => {
    const { board_id: id } = useParams()
    console.log(id)
    const [board, setBoard] = React.useState<GetBoardResponse | null>(null)
    const [ error, setError ] = React.useState<Error | null>(null)

    React.useEffect(() => {
        if (!id) {
            return
        }
        DefaultBoardService.getBoard(id)
            .then(res => setBoard(res))
            .catch(e => setError(e))

    }, [id])

    if (error) {
        return <div>Error: { JSON.stringify(error, null, 2) }</div>
    }

    if (!board) {
        return <div></div>
    }

    return <div>
        { JSON.stringify(board, null, 2) }
    </div>
}