import React from 'react'

import { Formik, Field, Form } from "formik"
import {
    DefaultBoardService,
    CreateBoardRequest,
    CreateBoardResponse
} from '../services/BoardService'
import { Navigate } from 'react-router'

import { Elements, } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CreatePaymentResponse, DefaultOrderService, OrderService } from '../services/OrderService';


import {useStripe, useElements, PaymentElement, ShippingAddressElement} from '@stripe/react-stripe-js';
import { PlacesAutocomplete } from '../components/AutoCompleteAddress';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://my-site.com/order/123/complete",
        // payment_method_data: {
        //     billing_details: {
                
        //     }
        // }
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {/* <ShippingAddressElement /> */}
      <button disabled={!stripe}>Submit</button>
      <PlacesAutocomplete />
    </form>
  )
};

const stripePromise = loadStripe('pk_test_DN5kuedIc1VxyacB2GZnOVAY')

function Payment(props: { payment:  CreatePaymentResponse }) {
    const options = {
        clientSecret: props.payment.payment_intent_client_secret,
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
        </Elements>
    );
};

export const CreateBoard = () => {
    const [board, setBoard] = React.useState<CreateBoardResponse>()
    const [payment, setPayment] = React.useState<CreatePaymentResponse>()

    const handleSubmit = async (values: CreateBoardRequest) => {
        const board = await DefaultBoardService.createBoard(values)
        if (board.id && board.order) {
            setBoard(board)
        }
    }

    React.useEffect(() => {
        if (!board || payment) return

        DefaultOrderService.createPayment(board.order.id)
            .then(setPayment)
    }, [board, payment])

    if (board && payment) {
        return <Payment payment={payment}  />
        // Payment
        // return <Navigate to={`/orders/${board.order.id}/payments/new`} />
    }

    const initialValues: CreateBoardRequest = {
        name: '',
        address: {
            street: '',
            unit_type: '',
            unit: '',
            city: '',
            state: '',
            zip: '',
        }

    }
    return <div>
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            <Form>
                <h1>Create Board</h1>
                <p>
                    Board Name:  <Field name="name" type="text" />
                </p>

                <p>
                    Street: <Field name="address.street" type="text" />
                </p>
                <p>
                    Unit Type: <Field name="address.unit_type" type="text" />
                </p>
                <p>
                    Unit: <Field name="address.unit" type="text" />
                </p>
                <p>
                    City:  <Field name="address.city" type="text" />
                </p>
                <p>
                    State:  <Field name="address.state" type="text" />
                </p>
                <p>
                    Zip:  <Field name="address.zip" type="text" />
                </p>
                <button type="submit">Submit</button>
            </Form>
        </Formik>
    </div>
}