export type GetBoardResponse = {
    id: string
    json: string
    created_at: string
    updated_at: string
}

export type Address = {
    street: string
    unit_type: string
    unit: string
    city: string
    state: string
    zip: string
}

export type CreateBoardRequest = {
    name: string
    address: Address
}

export type CreateOrderResponse = {
    id: string,
    order_state: string,
    amount: number,
    currency: string,
    payment_method_type: string,
    created_at: string,
    updated_at: string
}

export type CreateBoardResponse = {
    id: string
    json: string
    created_at: string
    updated_at: string
    order: CreateOrderResponse
}


export class BoardService {
    constructor(
        private readonly baseURL: string
    ) { }

    private url(...parts: string[]) {
        const path = parts.join('/')
        return `${this.baseURL}/${path}`
    }

    async getBoard(boardID: string) {
        return fetch(this.url('boards', boardID))
            .then(res => (res).json())
            .then(board => board as GetBoardResponse)
    }

    async createBoard(req: CreateBoardRequest) {
        return fetch(this.url('boards'), {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        })
            .then(res => res.json())
            .then(board => board as CreateBoardResponse)
    }
}

export const DefaultBoardService = new BoardService("https://6csuw7bnae.execute-api.us-east-1.amazonaws.com")
